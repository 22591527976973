

































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { User, Environment, ComponentType } from '@/models'
import * as ComponentTypes from '@/components/componentTypes'
import getEnv from '@/plugins/getEnv'

@Component({})
export default class AdminNavbar extends Vue {
  environment: Environment | null = null
  desktopApp = getEnv('VUE_APP_IS_DESKTOP')

  widthScreen: number = screen.width
  shouldShow = false
  collapsing = false

  collapse() {
    if (this.mini) return
    this.collapsing = true
    this.$nextTick(() => {
      this.collapsing = false
    })
    setTimeout(() => {
      this.mini = true
    }, 100)
  }

  mounted() {
    setTimeout(() => {
      this.shouldShow = true
    }, 200)
  }

  settings = [
    {
      title: 'General',
      icon: 'settings',
      href: { name: 'adminSettingsGeneral' }
    },
    {
      title: 'Apariencia',
      icon: 'palette',
      href: { name: 'adminSettingsAppearance' }
    },
    {
      title: 'Usuarios',
      icon: 'person',
      href: `/${this.$route.params.environmentId}/components/environmentUsers`
    },
    {
      title: 'Campos Perfil',
      icon: 'contact_mail',
      href: { name: 'adminSettingsProfile' }
    },
    {
      title: 'Variables De Entorno',
      icon: 'api',
      href: { name: 'adminSettingsEnv' }
    },
    {
      title: 'Roles',
      icon: 'group',
      href: `/${this.$route.params.environmentId}/components/roles`
    },
    {
      title: 'Navegación',
      icon: 'attachment',
      href: `/${this.$route.params.environmentId}/components/links`
    }
  ]

  logic = [
    {
      title: 'Filtros',
      icon: 'filter_list',
      href: `/${this.$route.params.environmentId}/components/filters`
    },
    {
      title: 'Validaciones',
      icon: 'spellcheck',
      href: `/${this.$route.params.environmentId}/components/validations`
    },
    {
      title: 'Hooks',
      icon: 'memory',
      href: `/${this.$route.params.environmentId}/components/hooks`
    },
    {
      title: 'Tareas',
      icon: 'event_available',
      href: `/${this.$route.params.environmentId}/components/tasks`
    }
  ]

  integrations = [
    {
      title: 'Endpoints V1',
      icon: 'language',
      href: `/${this.$route.params.environmentId}/components/endpoints`
    },
    {
      title: 'API',
      icon: 'account_tree',
      disabled: getEnv('VUE_APP_IS_DESKTOP'),
      href: `/${this.$route.params.environmentId}/components/api`
    }
  ]

  miniChange() {
    this.mini = !this.mini
  }

  @Watch('mini')
  overlayChange() {
    this.$store.commit('dashboard/setDesignOverlay', !this.mini)
  }

  get mini(): boolean {
    if (this.$store.state.dashboard.miniDrawer) {
      this.collapsing = true
      this.$nextTick(() => {
        this.collapsing = false
      })
    }
    return this.$store.state.dashboard.miniDrawer
  }

  set mini(val: boolean) {
    this.$store.commit('dashboard/setMiniDrawer', val)
  }

  get drawer(): boolean {
    let result = this.$store.state.dashboard.drawer
    return result
  }

  set drawer(val: boolean) {
    this.$store.commit('dashboard/setDrawer', val)
  }

  get showButtonBack() {
    if (this.widthScreen > 900) {
      return true
    }
  }

  // -------------------------

  changeEnvironment(environmentId: any) {
    return this.$router.push({
      name:
        (this.$route.name!.indexOf('admin') === 0 && this.$route.name) ||
        'admin',
      params: {
        environmentId
      }
    })
  }

  async logout() {
    await this.$store.dispatch('auth/logout')
    await this.$router.push('/login')
    return
  }

  toggleDarkMode() {
    this.$store.commit('app/toggleDarkMode')
  }

  get currentView() {
    if (this.$route.name === 'home') {
      return {
        icon: 'home',
        label: 'Inicio'
      }
    }
    if (this.$route.name === 'adminUser') {
      return {
        icon: 'person',
        label: 'Usuario'
      }
    }
    if (this.$route.name!.indexOf('admin') === 0) {
      return {
        icon: 'apps',
        label: this.currentEnvironment ? this.currentEnvironment.name : '',
        imageIcon:
          this.currentEnvironment?.loginLogo?.url ||
          this.currentEnvironment?.logo?.url
      }
    }
  }

  get currentEnvironment() {
    const firstEntry = this.$store.state.app.recents[0]
    if (firstEntry && firstEntry._id === this.envId) {
      return this.$store.state.app.recents[0]
    }
  }

  get envId() {
    return this.$route.params.environmentId
  }

  get loggedIn(): boolean {
    return this.$store.getters['auth/loggedIn']
  }

  get user(): User {
    return this.$store.state.auth.user
  }

  get userAvatar(): string {
    return this.$store.getters['auth/userAvatarURL']
  }

  get userRoleDisplay(): string {
    if (!this.user) return ''
    if (this.user.roles.includes('superAdmin')) {
      return 'Súper Administrador'
    }
    if (this.user.roles.includes('admin')) {
      return 'Administrador'
    }
    return 'Usuario'
  }

  /** Component types */
  componentTypes = ComponentTypes as Record<string, ComponentType<any>>

  get visibleComponentTypes() {
    return Object.keys(this.componentTypes)
      .map(
        (k) =>
          this.componentTypes[k] &&
          !this.componentTypes[k].hidden &&
          (!this.componentTypes[k].allowedEnvironments ||
            this.componentTypes[k].allowedEnvironments!.includes(this.envId)) &&
          this.componentTypes[k]
      )
      .filter((t) => t)
  }

  get showPadding(): boolean {
    return this.$store.state.dashboard.miniDrawer
  }
}
