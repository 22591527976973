

















import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormField } from '@/models'

interface TextAreaFieldOptions {
  icon?: string
  validateCaseInsensitive?: boolean
  validateCustomError?: string
  placeholder?: string
  max?: number
}

@Component({})
export default class TextAreaField extends Vue {
  /** Current Value */
  @Prop({ type: String, default: '' }) value!: string
  /** Validation Errors */
  @Prop() errorMessages!: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean

  /** Validation Rules */
  get validationRules() {
    const rules = []
    // Required validation
    if (!this.schema.optional) {
      rules.push((v?: string) => !!v || 'Campo requerido.')
    }
    // Max length validation
    if (this.fieldOptions.max) {
      rules.push(
        (v?: string) =>
          !v || v.length <= this.fieldOptions.max! || 'Demasiado largo'
      )
    }
    return rules
  }

  /** Additional field options */
  get fieldOptions(): TextAreaFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  /** Input event handler */
  handleInput(...args: any[]) {
    this.$emit('input', ...args)
  }
}
