var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%","background-color":"transparent"}},[(_vm.schema.label)?_c('h3',{staticClass:"subtitle-1 py-2",staticStyle:{"user-select":"none"},domProps:{"textContent":_vm._s(_vm.schema.label)}}):_vm._e(),_c('v-simple-table',{staticClass:"mb-2 px-1",attrs:{"id":"form-edit__fields-table"}},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"48px"}}),_c('th',{staticClass:"text-left",staticStyle:{"width":"25%"}},[_vm._v("Tipo")]),_c('th',{staticClass:"text-left",staticStyle:{"width":"25%"}},[_vm._v("Campo")]),_c('th',{staticClass:"text-left"},[_vm._v("Título / Valor")]),_c('th',{staticStyle:{"width":"48px"}},[_vm._v("Opcional")]),_c('th',{staticStyle:{"width":"48px"}}),_c('th',{staticStyle:{"width":"48px"}})])]),_c('draggable',{attrs:{"tag":"tbody","handle":".field-handle","animation":200,"scroll-sensitivity":128,"force-fallback":true},model:{value:(_vm.syncValue),callback:function ($$v) {_vm.syncValue=$$v},expression:"syncValue"}},_vm._l((_vm.value),function(field,index){return _c('tr',{key:index},[_c('td',{staticClass:"field-handle",staticStyle:{"width":"48px"}},[_c('v-icon',[_vm._v("drag_handle")])],1),_c('td',[_c('v-select',{attrs:{"solo":"","flat":"","dense":"","label":"Tipo de campo","items":_vm.fieldTypes,"value":field.type,"item-text":"label","item-value":"value","hide-details":""},on:{"input":function($event){return _vm.handleTypeChange(index, $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"left":"","color":item.color},domProps:{"textContent":_vm._s(item.icon)}}),_vm._v(" "+_vm._s(item.label)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"left":"","color":item.color},domProps:{"textContent":_vm._s(item.icon)}}),_vm._v(" "+_vm._s(item.label)+" ")]}}],null,true)})],1),_c('td',[_c('CollectionFieldSelect',{key:"fieldName",attrs:{"name":"fieldName","value":field.fieldName,"disabled":field.type === 'section',"schema":{
              label: 'Campo',
              fieldOptions: {
                collectionId: _vm.environmentVariables.collectionId,
                hideDetails: true,
                solo: true,
                flat: true,
                dense: true
              }
            },"environment-variables":_vm.environmentVariables},on:{"input":function($event){return _vm.handleOptionChange(index, { fieldName: $event })}}})],1),_c('td',[(['editable', 'section'].includes(field.type))?_c('v-text-field',{attrs:{"prepend-icon":"text_format","hide-details":"","solo":"","flat":"","dense":"","label":"Título","value":field.editableLabel},on:{"input":function($event){return _vm.handleOptionChange(index, { editableLabel: $event })}}}):(field.type === 'fixed')?_c('Field',{directives:[{name:"show",rawName:"v-show",value:(field.fieldName),expression:"field.fieldName"}],attrs:{"value":field.fixed && field.fixed.value,"name":field.fieldName,"schema":{
              label: 'Valor Fijo',
              type: _vm.collectionFieldType(index),

              fieldOptions: Object.assign({}, {hideDetails: true,
                solo: true,
                flat: true,
                dense: true},
                _vm.collectionFieldOptions(index))
            },"environment-variables":Object.assign({}, _vm.environmentVariables, {formId: undefined})},on:{"input":function($event){return _vm.handleOptionChange(index, { fixed: { value: $event } })}}}):(field.type === 'parameter')?_c('v-text-field',{attrs:{"prepend-icon":"settings_ethernet","hide-details":"","solo":"","flat":"","dense":"","label":"Nombre de Parámetro de Vista","value":field.parameterName},on:{"input":function($event){return _vm.handleOptionChange(index, { parameterName: $event })}}}):(field.type === 'indicator')?_c('ComponentSelect',{key:"indicatorId",attrs:{"name":"indicatorId","value":field.indicatorId,"schema":{
              label: 'Indicador a usar',
              fieldOptions: {
                componentTypeName: 'indicators',
                hideDetails: true,
                solo: true,
                flat: true,
                dense: true
              }
            },"environment-variables":_vm.environmentVariables},on:{"input":function($event){return _vm.handleOptionChange(index, { indicatorId: $event })}}}):_c('span',[_vm._v("N/A")])],1),_c('td',[_c('v-checkbox',{staticClass:"mx-3",attrs:{"input-value":field.optional,"disabled":!_vm.canBeOptional(index)},on:{"change":function($event){return _vm.handleOptionChange(index, { optional: $event })}}})],1),_c('td',[_c('v-bottom-sheet',{attrs:{"inset":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","text":"","icon":"","disabled":!['editable', 'indicator'].includes(field.type)}},on),[_c('v-icon',[_vm._v("build")])],1)]}}],null,true),model:{value:(_vm.optionSheetOpen[index]),callback:function ($$v) {_vm.$set(_vm.optionSheetOpen, index, $$v)},expression:"optionSheetOpen[index]"}},[_c('v-sheet',[(_vm.optionSheetOpen[index])?_c('v-container',[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-toolbar-title',[_vm._v("Opciones adicionales de campo")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.optionSheetOpen[index] = !_vm.optionSheetOpen[index]}}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-card-text',{staticStyle:{"overflow-y":"auto","max-height":"calc(100vh - 256px)"}},[(field.type === 'editable')?[_c('v-text-field',{attrs:{"prepend-icon":"text_format","label":"Valor por defecto","value":field.editableDefaultValue,"clearable":""},on:{"input":function($event){return _vm.handleOptionChange(index, {
                          editableDefaultValue: $event
                        })}}}),_c('v-slider',{attrs:{"label":"Ancho Móvil","min":"1","max":"12","thumb-label":"","ticks":"always","step":"1","value":field.sizeSmall},on:{"input":function($event){return _vm.handleOptionChange(index, { sizeSmall: $event })}}}),_c('v-slider',{attrs:{"label":"Ancho Largo","min":"1","max":"12","thumb-label":"","ticks":"always","step":"1","value":field.sizeLarge},on:{"input":function($event){return _vm.handleOptionChange(index, { sizeLarge: $event })}}}),_c('v-select',{attrs:{"label":"Condición de visibilidad","prepend-icon":"visibility","value":field.showField,"items":[
                        {
                          value: true,
                          text: 'El campo es visible si la condición se cumple'
                        },
                        {
                          value: false,
                          text: 'El campo es visible si la condición NO se cumple'
                        }
                      ]},on:{"input":function($event){return _vm.handleOptionChange(index, { showField: $event })}}}),_c('v-select',{attrs:{"label":"Tipo de condición","prepend-icon":"visibility","value":field.requiredType,"items":[
                        {
                          value: 'editable',
                          text: 'Campo del formulario contiene valor'
                        },
                        {
                          value: 'parameter',
                          text: 'Parámetro está definido'
                        },
                        {
                          value: 'paramEquals',
                          text: 'Campo del formulario contiene valor igual a parámetro definido'
                        }
                      ]},on:{"input":function($event){return _vm.handleOptionChange(index, { requiredType: $event })}}}),_c('v-slide-y-transition',[(['editable', 'paramEquals'].includes(field.requiredType))?_c('CollectionFieldSelect',{key:"requiredField",attrs:{"name":"requiredField","value":field.requiredField,"schema":{
                          label: 'Campo',
                          fieldOptions: {
                            collectionId: _vm.environmentVariables.collectionId
                          }
                        },"environment-variables":_vm.environmentVariables},on:{"input":function($event){return _vm.handleOptionChange(index, { requiredField: $event })}}}):_vm._e()],1),_c('v-slide-y-transition',[(
                          field.requiredType === 'editable' &&
                          field.requiredField
                        )?_c('Field',{key:"requiredValue",attrs:{"name":_vm.collectionRequiredFieldName(index),"value":field.requiredValue,"schema":{
                          label: 'Valor Requerido',
                          type: _vm.collectionRequiredFieldType(index),
                          fieldOptions: _vm.collectionRequiredFieldOptions(index)
                        },"environment-variables":_vm.environmentVariables},on:{"input":function($event){return _vm.handleOptionChange(index, { requiredValue: $event })}}}):_vm._e(),(
                          field.requiredType === 'paramEquals' &&
                          field.requiredField
                        )?_c('v-text-field',{staticClass:"mt-5",attrs:{"name":_vm.collectionRequiredFieldName(index),"prepend-icon":"settings_ethernet","label":"Nombre del parámetro","value":field.requiredValue,"clearable":""},on:{"input":function($event){return _vm.handleOptionChange(index, {
                            requiredValue: $event
                          })}}}):_vm._e()],1),_c('v-slide-y-transition',[(field.requiredType === 'parameter')?_c('v-text-field',{attrs:{"prepend-icon":"settings_ethernet","label":"Nombre del parámetro","value":field.requiredParameter,"clearable":""},on:{"input":function($event){return _vm.handleOptionChange(index, {
                            requiredParameter: $event
                          })}}}):_vm._e()],1)]:_vm._e(),(field.type === 'indicator')?[_c('v-text-field',{attrs:{"prepend-icon":"text_format","label":"Valor por defecto","value":field.indicatorDefaultValue,"clearable":""},on:{"input":function($event){return _vm.handleOptionChange(index, {
                          indicatorDefaultValue: $event
                        })}}})]:_vm._e()],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.optionSheetOpen[index] = !_vm.optionSheetOpen[index]}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("check")]),_vm._v(" Aceptar ")],1)],1)],1):_vm._e()],1)],1)],1),_c('td',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeField(index)}}},[_c('v-icon',[_vm._v("delete")])],1)],1)])}),0)],1),_c('v-row',{staticClass:"mx-0"},[_c('v-col',{staticClass:"pa-2"},[_c('v-btn',{attrs:{"block":"","dark":"","color":"#59a4f4"},on:{"click":_vm.addField}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("add")]),_vm._v(" Añadir Columna ")],1)],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.$apollo.loading,"width":"40px","block":"","dark":"","color":"#59a4f4"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" more_vert ")])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.addAllFields}},[_c('v-list-item-title',[_vm._v("Añadir todos los campos de la colección")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.removeAllFields}},[_c('v-list-item-title',[_vm._v("Limpiar todos campos")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }