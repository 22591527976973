













































































































































import { Component, Vue, Prop, Inject } from 'vue-property-decorator'
import { Collection, FieldTypeDefinition, FormField } from '@/models'
import FieldTypes from '@/components/fields'
import gql from 'graphql-tag'
import _mapValues from 'lodash/mapValues'

const FieldIcons = _mapValues(FieldTypes, (f: any) => f.Icon) as Record<
  string,
  string
>

interface CollectionFieldSelectFieldOptions {
  placeholder?: string
  collectionId?: string
  multi?: boolean
  parentCollection?: string
  displayOnly?: string
  allowedTypes?: string[]
  showCreatedAt?: boolean
}

interface CollectionFieldSelectFieldSchema extends FormField {
  parentCollection?: string
}

@Component({
  apollo: {
    collection: {
      query: gql`
        query collectionFields($collectionId: ID) {
          collection(collectionId: $collectionId) {
            _id
            fields {
              name
              type
              label
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
      variables() {
        return {
          collectionId: this.collectionId
        }
      }
    },
    fieldTypes: gql`
      query {
        fieldTypes {
          _id
          name
          optionsParams
        }
      }
    `
  }
})
export default class CollectionFieldSelectField extends Vue {
  /** Current Value */
  @Prop({ type: [String, Array], default: '' }) value!: string | string[]
  /** Validation Errors */
  @Prop() errorMessages!: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) })
  schema!: CollectionFieldSelectFieldSchema
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  /** Environment Variables */
  @Prop({ type: Object, default: () => ({}) }) environmentVariables!: Record<
    string,
    any
  >

  @Inject({ default: () => ({}) }) fieldValues!: () => Record<string, any>

  collection: Partial<Collection> | null = null
  fieldTypes: FieldTypeDefinition[] = []
  searchQuery = ''

  get fields() {
    if (this.$apollo.loading) return
    const fields = [
      {
        value: '_id',
        type: 'ID',
        name: 'ID',
        icon: 'storage',
        subtitle: 'Identificador Único Interno'
      },
      ...(this.collection?.fields?.map((field) => ({
        value: field.name,
        ...field,
        name: field.label,
        icon: FieldIcons[field.type] || 'data_usage',
        subtitle:
          (this.fieldTypes.find((f) => f._id === field.type) || {}).name ||
          field.type
      })) || [])
    ].filter(
      (f) =>
        !this.fieldOptions.allowedTypes ||
        this.fieldOptions.allowedTypes.includes(f.type)
    )

    if (this.fieldOptions.showCreatedAt) {
      fields.splice(1, 0, {
        value: 'createdAt',
        type: 'Date',
        name: 'Fecha de creación (BD)',
        icon: 'access_time',
        subtitle:
          'Campo interno que almacena automáticamente la fecha de creación'
      })
    }

    if (this.fieldOptions.displayOnly && this.fieldValues) {
      const values = this.fieldValues()
      const displayOnly = values[this.fieldOptions.displayOnly] as string[]
      return fields.filter((f) => displayOnly.includes(f.value))
    }

    return fields
  }

  /** Validation Rules */
  get validationRules() {
    const rules = []
    // Required validation
    if (!this.schema.optional) {
      rules.push((v?: string) => !!v || 'Campo requerido.')
    }
    return rules
  }

  /** Additional field options */
  get fieldOptions(): CollectionFieldSelectFieldOptions {
    return this.schema.fieldOptions || this.schema.options || {}
  }

  /** Input event handler */
  handleInput(...args: any[]) {
    this.$emit('input', ...args)
  }

  get collectionId() {
    if (this.fieldOptions.collectionId) return this.fieldOptions.collectionId
    if (this.fieldValues) {
      const values = this.fieldValues()
      if (this.schema.parentCollection)
        return values[this.schema.parentCollection]
      if (values.collectionId) return values.collectionId
    }
    if (this.environmentVariables.collectionId)
      return this.environmentVariables.collectionId
  }

  /** Current Value */
  get selectedValue() {
    if (!this.value) return ''
    return typeof this.value === 'string' ? this.value : this.value[0]
  }

  set selectedValue(value: string) {
    this.$emit('input', this.fieldOptions.multi ? value[0] : value)
  }

  /** Current Values (multi) */
  get selectedValues() {
    return typeof this.value === 'string' ? [this.value] : this.value
  }

  set selectedValues(value: string[]) {
    this.$emit('input', value)
  }

  get currentValue() {
    return this.fieldOptions.multi ? this.selectedValues : this.selectedValue
  }

  set currentValue(v) {
    if (this.fieldOptions.multi) {
      this.selectedValues = typeof v === 'string' ? [v] : v
    } else {
      this.selectedValue = typeof v === 'string' ? v : v[0]
    }
  }

  /** Remove a field */
  remove(field: string) {
    if (typeof this.value === 'string') {
      if (this.value === field) this.$emit('input', null)
      return
    } else {
      this.$emit(
        'input',
        this.value.filter((i) => i !== field)
      )
    }
  }
}
