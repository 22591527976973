














































































import { Component, Vue } from 'vue-property-decorator'
import Loading from '@/components/Loading.vue'
import PaginatedList from '@/components/PaginatedList.vue'
import gql from 'graphql-tag'
import { Environment, PaginatedQueryResult } from '../../models'
import MutationForm from '@/components/form/MutationForm.vue'

@Component({
  components: {
    Loading,
    PaginatedList,
    MutationForm
  }
})
export default class Environments extends Vue {
  createOpen = false

  async handleError(e: any) {
    await this.$store.dispatch('snackbar/showSnackbar', {
      text: 'Error: ' + e.message,
      color: 'error'
    })
  }

  handleCreate(env: Environment) {
    if (env && env._id) {
      return this.$router.push(`/${env._id}/`)
    }
  }

  transformResult(env: Partial<Environment>) {
    return {
      ...env,
      imgIcon: env?.loginLogo?.url || env?.logo?.url,
      title: env.name,
      subtitle: env._id,
      href: {
        name: 'admin',
        params: {
          environmentId: env._id
        }
      }
    }
  }
}
