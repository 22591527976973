





















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormField } from '@/models'

interface EmailFieldOptions {
  placeholder?: string
}

@Component({})
export default class EmailField extends Vue {
  /** Current Value */
  @Prop({ type: String, default: '' }) value!: string
  /** Validation Errors */
  @Prop() errorMessages!: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean

  /** Validation Rules */
  get validationRules() {
    const rules: Array<(data: string) => void> = []
    // Required validation
    if (!this.schema.optional) {
      rules.push((v?: string) => !!v || 'Campo requerido.')
    }
    // Email Validation
    rules.push(
      (v?: string) => !v || /\S+@\S+\.\S+/.test(v) || 'E-mail inválido'
    )
    return rules
  }

  /** Additional field options */
  get fieldOptions(): EmailFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  /** Input event handler */
  handleInput(...args: string[]) {
    this.$emit('input', ...args)
  }
}
