























































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import {
  Block,
  FormField,
  Hook,
  PaginatedQueryResult,
  TableFooterField
} from '@/models'
import { BlockFragment } from '@/components/componentTypes/blocks/fragments'
import ComponentSelect from '@/components/fields/componentSelect/Field.vue'
import CollectionFieldSelect from '@/components/fields/collectionFieldSelect/Field.vue'
import KeyValueList from '@/components/tools/KeyValueList.vue'
import HookSelect from '@/components/fields/hookSelect/Field.vue'

import Loading from '@/components/Loading.vue'
import _cloneDeep from 'lodash/cloneDeep'
import draggable from 'vuedraggable'
import _isEqual from 'lodash/isEqual'
import _snakeCase from 'lodash/snakeCase'

interface TableFooterFieldOptions {
  only?: string[]
}

@Component({
  components: {
    ComponentSelect,
    CollectionFieldSelect,
    Fields: () => import('@/components/form/Fields.vue'),
    Loading,
    draggable,
    KeyValueList,
    HookSelect
  }
})
export default class TableFooterEditField extends Vue {
  /** Current Value */
  @Prop({ type: Array, default: () => [] }) value!: {
    items: TableFooterField[]
  }[]
  /** Validation Errors */
  @Prop() errorMessages!: string
  /** Field Name */
  @Prop({ type: String, required: true }) name!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  /** Environment Variables */
  @Prop({ type: Object, required: true }) environmentVariables!: Record<
    string,
    any
  >

  iconSheetOpen: Record<number, boolean> = {}

  get fieldOptions(): TableFooterFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  get fieldTypes() {
    return [
      { value: 'text', label: 'Texto', icon: 'text_format' },
      { value: 'indicator', label: 'Indicador', icon: 'subtitles' },
      { value: 'parameter', label: 'Parámetro', icon: 'build' }
    ].filter((t) =>
      this.fieldOptions.only ? this.fieldOptions.only.includes(t.value) : true
    )
  }

  get syncValue() {
    return this.value
  }

  set syncValue(v) {
    this.$emit('input', v)
  }

  normalizedIcon(icon = '') {
    return icon.startsWith('Md') ? _snakeCase(icon.slice(2)) : icon
  }

  addRow() {
    this.$emit('input', [...this.value, { items: [] }])
  }

  addField(rowIndex: number) {
    this.$emit(
      'input',
      this.value.map((r, i) =>
        i === rowIndex ? { items: [...r.items, {}] } : r
      )
    )
  }

  setItems(rowIndex: number, items: TableFooterField[]) {
    this.$emit(
      'input',
      this.value.map((r, i) => (i === rowIndex ? { items: items } : r))
    )
  }

  handleTypeChange(rowIndex: number, index: number, type: string) {
    this.$emit(
      'input',
      this.value.map((r, i) =>
        i === rowIndex
          ? { items: r.items.map((f, i) => (i === index ? { ...f, type } : f)) }
          : r
      )
    )
  }

  handleOptionChange(
    rowIndex: number,
    index: number,
    optionName: string,
    value: any
  ) {
    this.$emit(
      'input',
      this.value.map((r, i) =>
        i === rowIndex
          ? {
              items: r.items.map((f, i) =>
                i === index ? { ...f, [optionName]: value } : f
              )
            }
          : r
      )
    )
  }

  removeRow(rowIndex: number) {
    this.$emit(
      'input',
      this.value.filter((r, i) => i !== rowIndex)
    )
  }

  removeField(rowIndex: number, index: number) {
    this.$emit(
      'input',
      this.value.map((r, i) =>
        i === rowIndex ? { items: r.items.filter((f, i) => i !== index) } : r
      )
    )
  }
}
