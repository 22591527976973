var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"login"},[_c('StatusBar',{attrs:{"statusStyle":"default"}}),_c('v-main',{class:{ background__logo: !_vm.authenticating }},[_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[(_vm.authenticating || _vm.$apollo.loading)?_c('v-flex',{staticClass:"login-container authenticating",attrs:{"xs12":""}},[_c('div',[_c('codeless-loading',{attrs:{"text":"CODELESS"}})],1)]):(_vm.domain && !_vm.environment)?_c('v-card',{staticClass:"login-card",attrs:{"elevation":"14","width":"560"}},[_c('v-card-text',{staticClass:"logged-in-greet text-center"},[_c('div',[_c('v-icon',{attrs:{"size":"96"}},[_vm._v("error")])],1),_c('br'),_c('div',{staticClass:"headline"},[_vm._v("URL Inválida")])])],1):_c('v-card',{staticClass:"login-card",attrs:{"elevation":"14","width":"560"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-4",attrs:{"cols":"11"}},[_c('v-card-title',[_vm._v("INICIAR SESIÓN")]),(_vm.logo)?_c('div',{staticClass:"logo"},[_c('img',{staticStyle:{"max-height":"96px","max-width":"480px"},attrs:{"src":_vm.logo}})]):_vm._e(),(!_vm.loggedIn)?_c('v-card-text',[(!_vm.forgot)?_c('MutationForm',{ref:"loginForm",attrs:{"mutation":"loginWithPassword","submitButtonText":"Iniciar Sesión","submitButtonIcon":"vpn_key","schema":{
                    email: {
                      label: 'E-Mail',
                      type: 'string',
                      fieldType: 'email',
                      newDesign: true,
                      fieldOptions: {
                        icon: 'person'
                      }
                    },
                    password: {
                      label: 'Contraseña',
                      type: 'string',
                      fieldType: 'password',
                      newDesign: true,
                      fieldOptions: {
                        icon: 'lock'
                      }
                    }
                  },"no-reset":""},on:{"success":_vm.loginSuccess}},[(!_vm.forgot)?_c('v-btn',{attrs:{"slot":"left-buttons","text":"","color":"primary"},on:{"click":function($event){_vm.forgot = true}},slot:"left-buttons"},[_vm._v("Olvidé mi contraseña")]):_vm._e()],1):_c('MutationForm',{ref:"forgotForm",attrs:{"mutation":"forgotPassword","subSelection":" ","schema":{
                    email: {
                      label: 'E-Mail',
                      type: 'string',
                      fieldType: 'email',
                      fieldOptions: {
                        icon: 'person'
                      }
                    }
                  },"no-reset":""},on:{"success":_vm.forgotSuccess}},[_c('v-btn',{attrs:{"slot":"right-buttons","text":"","color":"primary"},on:{"click":function($event){_vm.forgot = false}},slot:"right-buttons"},[_vm._v("Iniciar Sesión")])],1)],1):_vm._e()],1),_c('v-col',{staticStyle:{"background":"#619ddc","flex":"1"},attrs:{"cols":"1"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }