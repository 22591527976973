import { ComponentType, VueBlock } from '@/models'
import asyncImport from '@/utils/client/asyncImport'
import EditorLoading from './FullViewEdit/Loading.vue'
import ComponentError from '@/components/ComponentError.vue'

export const vueBlocks = {
  name: 'vueBlock',
  namespace: 'vueBlocks',
  title: 'Bloques Vue.js',
  titleSingle: 'Bloque Vue.js',
  description: 'Contenido interactivo/reactivo',
  icon: 'api',
  color: 'light-green',
  overview:
    'Los bloques Vue.js son una forma de incorporar contenido dinámico e interactivo en la plataforma. Son de uso avanzado.',
  queryName: 'vueBlocks',
  queryFields: `{
    _id
    name
    title
  }`,
  searchable: true,

  viewSkeleton: 'article',
  create: () => asyncImport(import('./Create.vue')),
  view: () => asyncImport(import('./View.vue')),
  edit: () => asyncImport(import('./Edit.vue')),
  fullViewEdit: () =>
    ({
      component: import('./FullViewEdit/FullViewEdit.vue').then(
        (e) => e.default
      ),
      loading: EditorLoading,
      error: ComponentError
    } as unknown as Vue.AsyncComponent)
} as ComponentType<VueBlock>
