




























































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { PaginatedQueryResult, FileManagerFile } from '@/models'
import { FileFragment } from './fragments'
import gql from 'graphql-tag'
import moment from '@/plugins/moment'
import Loading from '@/components/Loading.vue'
import mime from 'mime-types'
import axios from 'axios'
import FileUploadService from '../../../services/FileUploadService'
import { FileCredentialsResult } from '@/models/FileCredentails'

@Component({
  components: {
    Loading
  },
  apollo: {
    files: {
      query: gql`
        query getFiles($page: BigInt, $limit: BigInt, $filter: String) {
          files: fileManagerFiles(page: $page, limit: $limit, filter: $filter) {
            totalCount
            totalPages
            items {
              ...FileManagerFile
            }
          }
        }
        ${FileFragment}
      `,
      variables() {
        return {
          page: 1,
          limit: 100,
          filter: this.searchQuery
        }
      },
      skip() {
        return !this.browse
      }
    }
  },
  filters: {
    formatDate(input: string) {
      return moment(input).format('MM-DD-YYYY HH:mm')
    }
  }
})
export default class FileMultipleUploadModal extends Vue {
  @Prop({ type: Boolean, required: true }) open!: boolean
  @Prop({ type: String, default: '' }) fileType!: string
  @Prop({ type: Boolean, default: false }) inputPhoto!: boolean

  browse = false
  uploading = false
  hoveringFile = false
  uploadFilename = ''
  uploadProgress = ''
  uploadProgressNumeric = 0
  files: PaginatedQueryResult<FileManagerFile> | null = null
  searchQuery = ''
  selected = new Set<FileManagerFile>()
  uploadedFiles: FileManagerFile[] = []
  fileUploadService: FileUploadService = new FileUploadService(FileFragment)

  isSelected(file: FileManagerFile) {
    return this.selected.has(file)
  }

  updateSelected(file: FileManagerFile) {
    this.selected.has(file)
      ? this.selected.delete(file)
      : this.selected.add(file)
    this.$forceUpdate()
  }

  get dragAvailable() {
    return !window.mobileApp
  }

  get availableFiles() {
    if (!this.files) return []
    return this.files.items.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )
  }

  get modalOpen() {
    return this.open
  }

  get accept() {
    if (!this.fileType) return
    if (this.inputPhoto) return 'image/*'
    if (this.fileType.indexOf('/') < 0 && this.fileType.indexOf('.') < 0) {
      return `${this.fileType}/*`
    }
    return this.fileType
  }

  set modalOpen(v: boolean) {
    this.$emit('update:open', v)
    if (!v) {
      this.browse = false
      this.uploading = false
      this.uploadProgress = ''
      this.uploadProgressNumeric = 0
      this.uploadFilename = ''
      this.searchQuery = ''
    }
  }

  uploadFile() {
    const fileEl = this.$refs.file as HTMLInputElement
    fileEl.click()
  }

  filesSelected(selectedFiles: FileManagerFile[]) {
    this.$emit('select', [...selectedFiles])
    this.modalOpen = false
  }

  async processFileDrop(e: DragEvent) {
    if (this.uploading) return
    e.preventDefault()
    this.hoveringFile = false
    if (e.dataTransfer && e.dataTransfer.files.length > 0) {
      const files = Array.from(e.dataTransfer.files)
      try {
        if (files) {
          this.uploading = true
          for (const file of files) {
            let uploadedFile = await this.performFileMultipleUpload(file)
            this.uploadedFiles.push(uploadedFile)
          }
        }

        if (!this.browse) {
          this.$emit('select', this.uploadedFiles)
          this.modalOpen = false
        }
      } catch (e) {
        console.error(e)
        await this.$store.dispatch('snackbar/showSnackbar', {
          text: 'Error: ' + e.message,
          color: 'error',
          timeout: 5000
        })
      } finally {
        this.uploadFilename = ''
        this.uploadProgress = ''
        this.uploadProgressNumeric = 0
        this.uploading = false
      }
    }
  }

  async processFileInput() {
    if (this.uploading) return
    this.uploading = true
    try {
      const fileEl = this.$refs.file as HTMLInputElement
      const files = Array.from(fileEl.files as FileList)
      if (files && files.length > 0) {
        for (const file of files) {
          let uploadedFile = await this.performFileMultipleUpload(file)
          this.uploadedFiles.push(uploadedFile)
        }
      }

      if (!this.browse) {
        this.$emit('select', this.uploadedFiles)
        this.modalOpen = false
      }
    } catch (e) {
      console.error(e)
      await this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Error: ' + e.message,
        color: 'error',
        timeout: 5000
      })
    } finally {
      this.uploadFilename = ''
      this.uploadProgress = ''
      this.uploadProgressNumeric = 0
      this.uploading = false
    }
  }

  async performFileMultipleUpload(file: File) {
    this.uploadFilename = this.fileUploadService.generateFileName(file.name)

    if (this.fileUploadService.isValidFile(file, this.fileType)) {
      throw new Error('Tipo de archivo no permitido para este campo.')
    }

    this.uploadProgress = 'Obteniendo metadatos...'

    // Get upload credentials
    const { result: credentials } =
      await this.fileUploadService.getFileMetadata(file)

    if (!credentials) throw new Error('Error al obtener metadatos.')

    // Upload File (SIDE EFFECT)
    await this.physicallyUploadFileToS3(file, credentials)

    this.uploadProgress = 'Procesando Archivo...'
    this.uploadProgressNumeric = 0

    // Complete Upload
    const { data: result } = await this.fileUploadService.completeUpload(
      credentials
    )
    await this.$apollo.queries.files.refetch()
    return result.uploadedFile
  }

  async physicallyUploadFileToS3(
    file: File,
    credentials: FileCredentialsResult
  ) {
    await axios({
      url: credentials.url,
      method: 'post',
      transformRequest: [
        (data) => {
          const formData = new FormData()
          for (const key in data) {
            if (data.hasOwnProperty(key)) {
              formData.append(key, data[key])
            }
          }
          return formData
        }
      ],
      data: {
        ...credentials.fields,
        file
      },
      onUploadProgress: (progress) => {
        this.uploadProgressNumeric = (progress.loaded / progress.total) * 100
        this.uploadProgress = `${Math.round(this.uploadProgressNumeric)}%`
      }
    })
  }

  isValidFile(file: FileManagerFile) {
    if (!this.fileType) return true
    return file.type.indexOf(this.fileType) === 0
  }
}
