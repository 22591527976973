
















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class AlertDialog extends Vue {
  @Prop({ type: String }) title?: string
  @Prop({ type: String }) message?: string
  @Prop({ type: String, default: 'Aceptar' }) okButtonText?: string
  @Prop({ type: String, default: 'done' }) okButtonIcon?: string
  @Prop({ type: String, default: 'primary' }) okButtonColor?: string
}
