
















































































































































































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import {
  Block,
  Field,
  FormField,
  Hook,
  PaginatedQueryResult,
  Collection
} from '@/models'
import { BlockFragment } from '@/components/componentTypes/blocks/fragments'
import ComponentSelect from '@/components/fields/componentSelect/Field.vue'
import CollectionFieldSelect from '@/components/fields/collectionFieldSelect/Field.vue'
import KeyValueList from '@/components/tools/KeyValueList.vue'
import HookSelect from '@/components/fields/hookSelect/Field.vue'

import Loading from '@/components/Loading.vue'
import _cloneDeep from 'lodash/cloneDeep'
import draggable from 'vuedraggable'
import _isEqual from 'lodash/isEqual'
import _snakeCase from 'lodash/snakeCase'
import gql from 'graphql-tag'

interface FormFieldsFieldOptions {
  only?: string[]
}

@Component({
  components: {
    ComponentSelect,
    CollectionFieldSelect,
    Fields: () => import('@/components/form/Fields.vue'),
    Field: () => import('@/components/fields/Field.vue'),
    Loading,
    draggable,
    KeyValueList,
    HookSelect
  },
  apollo: {
    collection: {
      query: gql`
        query collectionFields($collectionId: ID) {
          collection(collectionId: $collectionId) {
            _id
            fields {
              name
              label
              type
              optional
              options
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
      variables() {
        return {
          collectionId: this.environmentVariables.collectionId
        }
      },
      skip() {
        return !this.environmentVariables?.collectionId
      }
    }
  }
})
export default class FormFieldsField extends Vue {
  /** Current Value */
  @Prop({ type: Array, default: () => [] }) value!: FormField[]
  /** Validation Errors */
  @Prop() errorMessages!: string
  /** Field Name */
  @Prop({ type: String, required: true }) name!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  /** Environment Variables */
  @Prop({ type: Object, required: true }) environmentVariables!: Record<
    string,
    any
  >

  collection: Partial<Collection> = {}

  optionSheetOpen: Record<number, boolean> = {}

  get fieldOptions(): FormFieldsFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  get fieldTypes() {
    return [
      {
        value: 'fixed',
        label: 'Valor Fijo',
        icon: 'build',
        color: 'blue-grey'
      },
      {
        value: 'editable',
        label: 'Editable',
        icon: 'keyboard',
        color: 'brown'
      },
      {
        value: 'parameter',
        label: 'Parámetro',
        icon: 'settings_ethernet',
        color: 'orange'
      },
      {
        value: 'indicator',
        label: 'Indicador',
        icon: 'subtitles',
        color: 'cyan'
      },
      { value: 'section', label: 'Sección', icon: 'grid_on' }
    ].filter((t) =>
      this.fieldOptions.only ? this.fieldOptions.only.includes(t.value) : true
    )
  }

  get syncValue() {
    return this.value
  }

  set syncValue(v) {
    this.$emit('input', v)
  }

  normalizedIcon(icon = '') {
    return icon.startsWith('Md') ? _snakeCase(icon.slice(2)) : icon
  }

  addField() {
    this.$emit('input', [
      ...(this.value || []),
      { type: 'editable', editableLabel: '' }
    ])
  }

  addAllFields() {
    if (!this.collection || !this.collection.fields) return
    const fields = this.collection.fields.map((f) => ({
      fieldName: f.name,
      type: 'editable',
      editableLabel: f.label,
      optional: f.optional
    }))
    this.$emit('input', [...(this.value || []), ...fields])
  }

  canBeOptional(index: number) {
    if (this.$apollo.loading) return false
    const f = this.value[index]
    if (f.type === 'section' || !f.type || !f.fieldName) return false
    return this.collection.fields?.find((cf) => cf.name === f.fieldName)
      ?.optional
  }

  collectionFieldType(index: number) {
    if (this.$apollo.loading) return ''
    const f = this.value[index]
    if (f.type === 'section' || !f.type || !f.fieldName) return ''
    return (
      this.collection.fields?.find((cf) => cf.name === f.fieldName)?.type || ''
    )
  }

  collectionFieldOptions(index: number) {
    if (this.$apollo.loading) return {}
    const f = this.value[index]
    if (f.type === 'section' || !f.type || !f.fieldName) return {}
    return (
      this.collection.fields?.find((cf) => cf.name === f.fieldName)?.options ||
      {}
    )
  }

  collectionRequiredFieldType(index: number) {
    if (this.$apollo.loading) return ''
    const f = this.value[index]
    if (f.type === 'section' || !f.type || !f.fieldName) return ''
    return (
      this.collection.fields?.find((cf) => cf.name === f.requiredField)?.type ||
      ''
    )
  }

  collectionRequiredFieldOptions(index: number) {
    if (this.$apollo.loading) return {}
    const f = this.value[index]
    if (f.type === 'section' || !f.type || !f.fieldName) return {}
    return (
      this.collection.fields?.find((cf) => cf.name === f.requiredField)
        ?.options || {}
    )
  }

  collectionRequiredFieldName(index: number) {
    if (this.$apollo.loading) return ''
    const f = this.value[index]
    if (f.type === 'section' || !f.type || !f.fieldName) return ''
    return (
      this.collection.fields?.find((cf) => cf.name === f.requiredField)?.name ||
      ''
    )
  }

  handleTypeChange(index: number, type: string) {
    this.$emit(
      'input',
      this.value.map((f, i) =>
        i === index ? { ...f, type, options: {}, label: '' } : f
      )
    )
  }

  handleOptionChange(index: number, delta: Partial<FormField>) {
    this.$emit(
      'input',
      this.value.map((f, i) => (i === index ? { ...f, ...delta } : f))
    )
  }

  removeField(index: number) {
    this.$emit(
      'input',
      this.value.filter((f, i) => i !== index)
    )
  }

  removeAllFields() {
    this.$emit('input', [])
  }
}
