import { RootState } from '@/store/index'
import { Module } from 'vuex'
import { Environment } from '@/models'

export interface AppState {
  darkMode: boolean
  editLinks: boolean
  recents: Partial<Environment>[]
}

/**
 * Authentication Module - Stores info about the currently logged in user.
 */
const AppModule: Module<AppState, RootState> = {
  namespaced: true,
  state: {
    darkMode: !!window.localStorage.darkMode,
    editLinks: !!window.localStorage.codelessEditLinks,
    recents: JSON.parse(localStorage.codelessRecents || '[]')
  },
  mutations: {
    /**
     * Toggle Dark Mode
     * @param state - Current app state
     */
    toggleDarkMode(state) {
      state.darkMode = !state.darkMode
      if (state.darkMode) {
        localStorage.darkMode = 1
      } else {
        delete localStorage.darkMode
      }
    },
    /**
     * Toggle Edit Links
     * @param state - Current app state
     */
    toggleEditLinks(state) {
      state.editLinks = !state.editLinks
      if (state.editLinks) {
        localStorage.codelessEditLinks = 1
      } else {
        delete localStorage.codelessEditLinks
      }
    },
    /**
     * Add an environment to the recents list.
     */
    addRecent(state, env: Partial<Environment>) {
      // Find and remove current entry
      const currentIndex = state.recents.findIndex((e) => e._id === env._id)
      if (currentIndex >= 0) {
        state.recents.splice(currentIndex, 1)
      }
      // Add new entry
      state.recents.unshift(env)
      // Limit to 5 entries
      if (state.recents.length > 5) {
        state.recents = state.recents.slice(0, 5)
      }
      localStorage.codelessRecents = JSON.stringify(state.recents)
    }
  },
  getters: {},
  actions: {}
}

export default AppModule
