















































































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import {
  PaginatedQueryResult,
  Hook,
  HookFunctionType,
  FormField
} from '@/models'
import { HookFragment } from '@/components/componentTypes/hooks/fragments'
import draggable from 'vuedraggable'
import Loading from '@/components/Loading.vue'
import gql from 'graphql-tag'
import { confirm } from '@/components/dialogs'

interface HookSelectFieldOptions {
  placeholder?: string
  multi?: boolean
  allowedTypes?: string[]
}

@Component({
  components: {
    Loading,
    draggable,
    ComponentEditorDialog: () =>
      import('@/components/ComponentEditorDialog.vue').then((c) => c.default),
    ComponentCreateDialog: () =>
      import('@/components/ComponentCreateDialog.vue').then((c) => c.default)
  },
  apollo: {
    hooks: {
      query: gql`
        query getEnvironmentHooks($environmentId: ID) {
          hooks(environmentId: $environmentId) {
            items {
              ...Hook
            }
          }
        }
        ${HookFragment}
      `,
      variables() {
        return {
          environmentId: this.environmentId
        }
      }
    },
    functionTypes: gql`
      query {
        functionTypes {
          _id
          name
        }
      }
    `
  }
})
export default class HookSelectField extends Vue {
  /** Current Value */
  @Prop({ type: [String, Array], default: '' }) value!: string | string[]
  /** Validation Errors */
  @Prop() errorMessages!: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  /** Environment Variables */
  @Prop({ type: Object, required: true }) environmentVariables!: Record<
    string,
    any
  >

  hooks: PaginatedQueryResult<Hook> | null = null
  functionTypes: HookFunctionType[] | null = null

  repeatedHooks = false

  addingHook = false
  pendingHookId = ''
  dragging = false

  createModalOpen = false

  hookEditorOpen = false
  hookEditorId = ''

  @Watch('pendingHookId')
  addHook(hookId: string) {
    if (!hookId) return
    this.selectedHookIds = [...this.selectedHookIds, hookId]
    this.addingHook = false
    this.pendingHookId = ''
    if (this.value) {
      if (this.value.includes(hookId)) {
        this.repeatedHooks = true
      }
    }
  }

  editHook(hookId: string) {
    this.hookEditorId = hookId
    this.hookEditorOpen = true
  }

  removeHook(index: number) {
    this.selectedHookIds.splice(index, 1)
    this.repeatedHooks = this.hasDuplicates(this.value)
  }

  hasDuplicates(array: string | string[]) {
    let valuesSoFar = Object.create(null)
    for (let i = 0; i < array.length; ++i) {
      let value: string = array[i]
      if (value in valuesSoFar) {
        return true
      }
      valuesSoFar[value] = true
    }
    return false
  }

  async clear() {
    if (this.fieldOptions.multi) {
      if (
        await confirm('¿Seguro que quieres limpiar la lista de hooks?', {
          okButtonText: 'Si',
          cancelButtonText: 'No'
        })
      ) {
        this.$emit('input', [])
      }
    } else {
      this.$emit('input', null)
    }
  }

  /** Items */
  get items(): Hook[] {
    return (
      (this.hooks &&
        this.hooks.items.map((i) => {
          const typeDef = (this.functionTypes || []).find(
            (t) => t._id === i.functionTypeId
          )
          return {
            ...i,
            functionTypeName: typeDef ? typeDef.name : i.functionTypeId,
            legacy: i.functionTypeId !== 'kraken'
          }
        })) ||
      []
    ).filter((i) => {
      if (
        this.fieldOptions.allowedTypes &&
        this.fieldOptions.allowedTypes.length
      ) {
        return this.fieldOptions.allowedTypes.includes(i.functionTypeId)
      }
      return true
    })
  }

  /** Environment ID */
  get environmentId(): string {
    return this.environmentVariables.environmentId
  }

  /** Validation Rules */
  get validationRules() {
    const rules = []
    // Required validation
    if (!this.schema.optional) {
      rules.push((v?: string) => !!v || 'Campo requerido.')
    }
    return rules
  }

  /** Additional field options */
  get fieldOptions(): HookSelectFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  /** Current Hook ID */
  get selectedHookId() {
    if (!this.value) return ''
    return typeof this.value === 'string' ? this.value : this.value[0]
  }

  set selectedHookId(value: string) {
    this.$emit('input', this.fieldOptions.multi ? value[0] : value)
  }

  /** Current Hook IDs (multi) */
  get selectedHookIds() {
    if (!this.value) return []
    return typeof this.value === 'string' ? [this.value] : this.value
  }

  set selectedHookIds(value: string[]) {
    this.$emit('input', value)
  }

  /** Selected Hooks */
  get selectedHooks() {
    if (!this.hooks) return []
    return this.selectedHookIds
      .map((h) => this.hooks!.items.find((i) => i._id === h))
      .filter((h) => h)
  }
}
