var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PaginatedList',{attrs:{"queryName":"environments","queryFields":"{\n      _id\n      name\n      url\n      logo {\n        url\n      }\n      loginLogo {\n        url\n      }\n    }","addButton":"","searchBox":"","squareIcons":"","addButtonColor":"rgb(89, 164, 244)","defaultIcon":"apps","limit":50,"transformResult":_vm.transformResult},on:{"addButtonClick":function($event){_vm.createOpen = true}}}),_c('v-dialog',{attrs:{"width":"900"},model:{value:(_vm.createOpen),callback:function ($$v) {_vm.createOpen=$$v},expression:"createOpen"}},[_c('v-card',{staticClass:"card--flex-toolbar"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-icon',{attrs:{"large":"","left":""}},[_vm._v("apps")]),_c('div',{staticClass:"title"},[_c('h1',{staticClass:"headline"},[_vm._v("Crear Entorno")])]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){_vm.createOpen = false}}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-container',{staticClass:"edit-modal-container"},[_c('v-card-text',[_c('MutationForm',{attrs:{"no-reset":"","mutation":"createEnvironment","submitButtonText":"Crear","submitButtonIcon":"add","schema":{
              _id: {
                __graphQLType: 'ID',
                label: 'Espacio de nombres',
                description: 'Sólo puede haber un ambiente con este nombre.',
                type: 'string',
                newDesign: true
              },
              name: {
                label: 'Nombre para mostrar',
                type: 'string',
                description:
                  'Debe ser único y reconocible. Será visible en la barra de título',
                newDesign: true
              },
              url: {
                label: 'URL',
                type: 'string',
                description: 'Opcional. Se mostrará en las vistas.',
                newDesign: true
              }
            }},on:{"success":_vm.handleCreate,"error":_vm.handleError}},[_c('v-btn',{attrs:{"slot":"left-buttons","text":""},on:{"click":function($event){_vm.createOpen = false}},slot:"left-buttons"},[_vm._v("Cancelar")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }