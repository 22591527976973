































import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormField } from '@/models'
import SelectModal from './SelectModal.vue'
import _pick from 'lodash/pick'

interface LocationFieldOptions {
  gpsOnly?: boolean
  limitCountry?: string
}
@Component({
  components: {
    SelectModal
  }
})
export default class LocationField extends Vue {
  /** Current Value */
  @Prop({ type: String }) value?: string
  /** Validation Errors */
  @Prop() errorMessages!: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean

  selectModalOpen = false
  isLoading = false

  openModal() {
    // if (this.value) return
    if (this.fieldOptions.gpsOnly) {
      const selectModal = this.$refs.selectModal as any
      selectModal.selectCurrentLocationGPS()
    }
    this.selectModalOpen = true
  }

  /** Validation Rules */
  get validationRules() {
    const rules = []
    // Required validation
    if (!this.schema.optional) {
      rules.push((v?: string) => !!v || 'Campo requerido.')
    }
    return rules
  }

  /** Additional field options */
  get fieldOptions(): LocationFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  get displayValue() {
    if (!this.value) return
    return `Coordenadas: [${this.value}]`
  }

  handleSelect(location: string) {
    this.$emit('input', location)
  }

  clearValue() {
    this.$emit('input', null)
  }
}
