export interface Task {
  _id: string
  name: string
  environmentId: string
  createdAt: Date
  execConditions: any
  status: TaskStatus
  statusLog: TaskStatusEntry[]
  executionTimeout: number
  maxMemoryMB: number
  ephemeral: boolean
  script: string
  lastExecutionTime: Date
  lastExecutionLog: TaskLogEntry[]
  parentTask: string
  invokeOnFail: string | null
}

export interface TaskLogEntry {
  t: Date
  m: string
}

export interface TaskStatusEntry {
  timestamp: Date
  status: TaskStatus
  reason: string
  childTaskId?: string
}

export enum TaskStatus {
  Stopped = 'stopped',
  Running = 'running',
  Errored = 'errored',
  Interrupted = 'interrupted',
  Queued = 'queued'
}
