













































































































































































































































































import { Vue, Component, Prop, Inject } from 'vue-property-decorator'
import {
  MixedChartSeries,
  MixedChartSeriesType,
  MixedChartSeriesDisplayType,
  MixedChartSortType
} from '@/models'
import draggable from 'vuedraggable'
import Chance from 'chance'
import CollectionFieldSelect from '@/components/fields/collectionFieldSelect/Field.vue'
import ComponentSelect from '@/components/fields/componentSelect/Field.vue'

@Component({
  components: {
    draggable,
    CollectionFieldSelect,
    ComponentSelect
  }
})
export default class MixedChartSeriesField extends Vue {
  /** Current Value */
  @Prop({ type: Array, default: [] }) value?: MixedChartSeries[]
  /** Validation Errors */
  @Prop() errorMessages!: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: any
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  /** Environment Variables */
  @Prop({ type: Object, default: () => ({}) }) environmentVariables!: Record<
    string,
    any
  >

  @Inject() fieldValues?: () => Record<string, any>

  defaultColors = [
    '#2196F3',
    '#F44336',
    '#E91E63',
    '#9C27B0',
    '#8BC34A',
    '#00BCD4',
    '#FFEB3B',
    '#795548',
    '#607D8B',
    '#CDDC39',
    '#FF9800',
    '#4CAF50'
  ]

  chartDisplayTypes = [
    { value: 'column', label: 'Barra', icon: 'bar_chart' },
    { value: 'line', label: 'Línea', icon: 'show_chart' },
    { value: 'area', label: 'Área', icon: 'signal_cellular_4_bar' },
    { value: 'dot', label: 'Puntos', icon: 'scatter_plot' },
    { value: 'curvedLine', label: 'Línea Curva', icon: 'multiline_chart' }
  ]

  getDisplayType(series: MixedChartSeries) {
    return this.chartDisplayTypes.find((t) => t.value === series.displayType)
  }

  addSeries() {
    this.series = [
      ...this.series,
      {
        _id: new Chance().string({ length: 17, alpha: true, numeric: true }),
        type: MixedChartSeriesType.Average,
        displayType: MixedChartSeriesDisplayType.Column,
        label: 'Serie #' + (this.series.length + 1),
        color:
          this.defaultColors[this.series.length % this.defaultColors.length],
        sortType: MixedChartSortType.NoPreference
      }
    ]
  }

  removeSeries(series: MixedChartSeries) {
    this.$emit(
      'input',
      this.series.filter((s) => series._id !== s._id)
    )
  }

  update() {
    this.$nextTick(() => this.$emit('input', this.series))
  }

  get series() {
    return this.value || []
  }

  set series(v: MixedChartSeries[]) {
    this.$emit('input', v)
  }

  get referenceAxis() {
    return this.series.find((s) => s.referenceAxis)
  }
}
