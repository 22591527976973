import { User } from '@/models'
import getClient, {
  performLogin,
  performLogout
} from '@/plugins/vue-apollo/client'
import { Session } from '@/models/Session'
import { RootState } from '@/store/index'
import md5 from 'md5'
import gql from 'graphql-tag'
import { Module } from 'vuex'
import getPushProvider from '@/utils/pushNotifications'

export interface AuthState {
  user?: User
  session?: Session
}

export interface LoginPayload {
  email: string
  password: string
}

/**
 * Authentication Module - Stores info about the currently logged in user.
 */
const AuthModule: Module<AuthState, RootState> = {
  namespaced: true,
  state: {
    user: undefined,
    session: undefined
  },
  mutations: {
    /**
     * Set session data
     * @param state - Current auth state
     * @param session - New session data
     */
    setSessionData(state, session: Session) {
      state.session = session
    },

    /**
     * Set user data
     * @param state - Current auth state
     * @param userData - New user data
     */
    setUserData(state, userData: User) {
      state.user = userData
    }
  },
  getters: {
    /**
     * Is the user currently logged in?
     */
    loggedIn(state) {
      return state.session && state.user
    },
    /**
     * Current user's gravatar URL.
     */
    userAvatarURL(state) {
      if (state.user && state.user.profile && state.user.profile.avatar) {
        return state.user.profile.avatar.url
      }
      // Gravatar Fallback
      const mainEmail =
        (state.user && (state.user.email || state.user._id)) || ''

      const hash = md5(mainEmail.toLowerCase().trim())
      return `https://www.gravatar.com/avatar/${hash}?d=mp&s=512`
    },
    /**
     * Current session for chat
     */
    session(state) {
      return state.session
    }
  },
  actions: {
    /** Perform logout */
    async logout({ commit }) {
      await performLogout()
      commit('setUserData', undefined)
      delete window.localStorage.session
      if (localStorage.mobEnvironment) delete localStorage.mobEnvironment
      await getPushProvider().unsubscribe()
      window.location.reload()
    },

    /** Update session object */
    async loadSession({ commit }, session: Session) {
      const client = getClient()
      await performLogin(session)

      window.localStorage.session = JSON.stringify(session)

      commit('setSessionData', session)

      if (window.localStorage.userData) {
        commit('setUserData', JSON.parse(window.localStorage.userData))
      }

      const { data } = await client.query({
        query: gql`
          query getUser($userId: ID) {
            user(userId: $userId) {
              _id
              email
              createdAt
              roles
              profile {
                name
                avatar {
                  _id
                  name
                  type
                  key
                  bucket
                  url
                }
                firstName
                lastName
              }
              environmentsAuthorized
              hasTwoFactor
            }
          }
        `,
        variables: { userId: session.userId }
      })

      window.localStorage.userData = JSON.stringify(data.user)
      commit('setUserData', data.user)
    },

    /** Resume a previous session (from local storage) */
    async resumeSession({ dispatch }) {
      const prevSession = JSON.parse(
        window.localStorage.session || 'null'
      ) as Session
      try {
        if (prevSession) {
          await dispatch('loadSession', prevSession)
          return true
        }
      } catch (e) {
        console.error(e)
      }
      return false
    }
  }
}

export default AuthModule
