var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[(_vm.schema.label)?_c('h3',{staticClass:"subtitle-1 py-2",staticStyle:{"user-select":"none"},domProps:{"textContent":_vm._s(_vm.schema.label)}}):_vm._e(),_c('v-simple-table',{staticClass:"mb-2 px-1",attrs:{"id":"table-edit__columns-table"}},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"48px"}}),_c('th',{staticClass:"text-left",staticStyle:{"width":"25%"}},[_vm._v("Tipo")]),_c('th',{staticClass:"text-left"},[_vm._v("Título / Ícono")]),_c('th',{staticClass:"text-left"},[_vm._v("Valor")]),_c('th',{staticStyle:{"width":"48px"}})])]),_c('draggable',{attrs:{"tag":"tbody","handle":".field-handle","animation":200,"scroll-sensitivity":128,"force-fallback":true},model:{value:(_vm.syncValue),callback:function ($$v) {_vm.syncValue=$$v},expression:"syncValue"}},_vm._l((_vm.value),function(field,index){return _c('tr',{key:index},[_c('td',{staticClass:"field-handle",staticStyle:{"width":"48px"}},[_c('v-icon',[_vm._v("drag_handle")])],1),_c('td',[_c('v-select',{attrs:{"solo":"","flat":"","dense":"","label":"Tipo de Columna","items":_vm.fieldTypes,"value":field.type,"item-text":"label","item-value":"value","hide-details":""},on:{"input":function($event){return _vm.handleTypeChange(index, $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s(item.icon)}}),_vm._v(" "+_vm._s(item.label)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s(item.icon)}}),_vm._v(" "+_vm._s(item.label)+" ")]}}],null,true)})],1),_c('td',[(
              [
                'field',
                'editableField',
                'indicator',
                'multipleSelectGroup'
              ].includes(field.type)
            )?_c('v-text-field',{attrs:{"prepend-icon":"text_format","hide-details":"","solo":"","flat":"","dense":"","label":field.type === 'multipleSelectGroup'
                ? 'Título del grupo'
                : 'Título de la columna',"value":field.label},on:{"input":function($event){return _vm.handleLabelChange(index, $event)}}}):(
              [
                'selectIconButton',
                'routeIconButton',
                'deleteRowByUser',
                'runHooks',
                'postItem',
                'modal'
              ].includes(field.type)
            )?_c('v-text-field',{attrs:{"prepend-icon":_vm.normalizedIcon(field.options && field.options.icon),"hide-details":"","solo":"","flat":"","dense":"","label":"Ícono para el botón","value":field.options && field.options.icon},on:{"change":function($event){return _vm.handleOptionChange(index, 'icon', $event)}}}):_c('div',{staticClass:"text-center"},[_vm._v("N/A")])],1),_c('td',[(['field'].includes(field.type))?_c('CollectionFieldSelect',{key:"fieldName",attrs:{"name":"fieldName","value":field.fieldName,"schema":{
              label: 'Campo',
              fieldOptions: {
                collectionId: _vm.environmentVariables.collectionId,
                hideDetails: true,
                solo: true,
                flat: true,
                dense: true
              }
            },"environment-variables":_vm.environmentVariables},on:{"input":function($event){return _vm.handleFieldChange(index, $event)}}}):(field.type === 'editableField')?_c('v-bottom-sheet',{attrs:{"inset":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","text":"","block":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("build")]),_vm._v(" Configurar Campo Editable ")],1)]}}],null,true),model:{value:(_vm.editableSheetOpen[index]),callback:function ($$v) {_vm.$set(_vm.editableSheetOpen, index, $$v)},expression:"editableSheetOpen[index]"}},[_c('v-sheet',[(_vm.editableSheetOpen[index])?_c('v-container',[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-toolbar-title',[_vm._v("Opciones de campo editable")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.editableSheetOpen[index] = !_vm.editableSheetOpen[index]}}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-card-text',{staticStyle:{"overflow-y":"auto","max-height":"calc(100vh - 256px)"}},[_c('CollectionFieldSelect',{key:"fieldName",attrs:{"name":"fieldName","value":field.fieldName,"schema":{
                      label: 'Campo',
                      fieldOptions: {
                        collectionId: _vm.environmentVariables.collectionId
                      },
                      newDesign: true
                    },"environment-variables":_vm.environmentVariables},on:{"input":function($event){return _vm.handleFieldChange(index, $event)}}}),_c('v-checkbox',{attrs:{"label":"Guardar al cambiar","input-value":field.options && field.options.saveOnChange},on:{"change":function($event){return _vm.handleOptionChange(index, 'saveOnChange', $event)}}}),_c('v-checkbox',{attrs:{"label":"Guardar al hacer click afuera","input-value":field.options && field.options.saveOnClickOutside},on:{"change":function($event){return _vm.handleOptionChange(index, 'saveOnClickOutside', $event)}}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.editableSheetOpen[index] = !_vm.editableSheetOpen[index]}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("check")]),_vm._v(" Aceptar ")],1)],1)],1):_vm._e()],1)],1):(field.type === 'indicator')?_c('ComponentSelect',{key:"indicatorId",attrs:{"name":"indicatorId","value":field.options && field.options.indicatorId,"schema":{
              label: 'Indicador a mostrar',
              fieldOptions: {
                componentTypeName: 'indicators',
                hideDetails: true,
                solo: true,
                flat: true,
                dense: true
              }
            },"environment-variables":_vm.environmentVariables},on:{"input":function($event){return _vm.handleOptionChange(index, 'indicatorId', $event)}}}):(
              ['multipleSelect', 'multipleSelectGroup'].includes(field.type)
            )?_c('ComponentSelect',{key:"buttonsId",attrs:{"name":"buttonsId","value":field.options && field.options.buttonsIds,"schema":{
              label: 'Botones / Acciones a mostrar',
              fieldOptions: {
                componentTypeName: 'buttons',
                multi: true,
                hideDetails: true,
                solo: true,
                flat: true,
                dense: true
              }
            },"environment-variables":_vm.environmentVariables},on:{"input":function($event){return _vm.handleOptionChange(index, 'buttonsIds', $event)}}}):(
              [
                'selectIconButton',
                'routeIconButton',
                'deleteRowByUser',
                'runHooks',
                'postItem',
                'modal',
                'inlineBlock'
              ].includes(field.type)
            )?_c('v-bottom-sheet',{attrs:{"inset":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","text":"","block":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("edit")]),_vm._v(" Editar Botón ")],1)]}}],null,true),model:{value:(_vm.iconSheetOpen[index]),callback:function ($$v) {_vm.$set(_vm.iconSheetOpen, index, $$v)},expression:"iconSheetOpen[index]"}},[_c('v-sheet',[(_vm.iconSheetOpen[index])?_c('v-container',[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-toolbar-title',[_vm._v("Opciones adicionales de columna")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.iconSheetOpen[index] = !_vm.iconSheetOpen[index]}}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-card-text',{staticStyle:{"overflow-y":"auto","max-height":"calc(100vh - 256px)"}},[(field.type !== 'inlineBlock')?_c('v-text-field',{attrs:{"label":"Tooltip","prepend-icon":"text_format","hint":"Se muestra al pasar el cursor por encima del ícono","value":field.options.tooltip},on:{"input":function($event){return _vm.handleOptionChange(index, 'tooltip', $event)}}}):_vm._e(),(field.type === 'selectIconButton')?[_c('v-text-field',{attrs:{"label":"Parámetro de la vista a actualizar","hint":"Al hacer click en el botón, se actualizará el parámetro especificado acá","prepend-icon":"build","value":field.options.variableTo},on:{"input":function($event){return _vm.handleOptionChange(index, 'variableTo', $event)}}}),_c('CollectionFieldSelect',{key:"variableFrom",attrs:{"name":"variableFrom","schema":{
                        label: 'Valor de la fila a colocar en el parámetro',
                        fieldOptions: {
                          collectionId: _vm.environmentVariables.collectionId
                        }
                      },"environment-variables":_vm.environmentVariables,"value":field.options.variableFrom},on:{"input":function($event){return _vm.handleOptionChange(index, 'variableFrom', $event)}}})]:_vm._e(),(
                      ['deleteRowByUser', 'runHooks'].includes(field.type)
                    )?[_c('v-text-field',{attrs:{"label":"Texto Modal","hint":"Opcional. Se solicitará confirmación del usuario antes de ejecutar el botón.","prepend-icon":"build","value":field.options && field.options.modalText},on:{"input":function($event){return _vm.handleOptionChange(index, 'modalText', $event)}}}),_c('HookSelect',{attrs:{"name":"hooksIds","value":(field.options && field.options.hooksIds) || [],"schema":{
                        label: 'Hooks a ejecutar',
                        fieldOptions: {
                          multi: true
                        }
                      },"environment-variables":_vm.environmentVariables},on:{"input":function($event){return _vm.handleOptionChange(index, 'hooksIds', $event)}}}),_c('v-checkbox',{attrs:{"label":"Detener ejecución de hooks si ocurre un error","input-value":field.options && field.options.shouldStopHooksOnError},on:{"change":function($event){return _vm.handleOptionChange(
                          index,
                          'shouldStopHooksOnError',
                          $event
                        )}}}),_c('v-checkbox',{attrs:{"label":"Requerir autenticación de 2 factores","input-value":field.options && field.options.requireTwoFactor},on:{"change":function($event){return _vm.handleOptionChange(index, 'requireTwoFactor', $event)}}})]:_vm._e(),(
                      ['routeIconButton', 'runHooks'].includes(field.type)
                    )?[(field.type === 'runHooks')?_c('h2',{staticClass:"headline"},[_vm._v(" Redirigir a vista luego de ejecutar hooks ")]):_vm._e(),_c('ComponentSelect',{key:"viewPath",attrs:{"name":"viewPath","value":field.options && field.options.viewPath,"schema":{
                        label: 'Destino',
                        fieldOptions: {
                          componentTypeName: 'views',
                          itemValue: 'path'
                        }
                      },"environment-variables":_vm.environmentVariables},on:{"input":function($event){return _vm.handleOptionChange(index, 'viewPath', $event)}}}),_c('v-checkbox',{attrs:{"label":"Abrir en nueva pestaña"},model:{value:(field.options.newTab),callback:function ($$v) {_vm.$set(field.options, "newTab", $$v)},expression:"field.options.newTab"}}),_c('div',{staticClass:"ml-8",domProps:{"textContent":_vm._s(field.options.viewPath)}}),_c('KeyValueList',{attrs:{"value":field.options && field.options.variableMap,"keyLabel":"Parámetro"},on:{"input":function($event){return _vm.handleOptionChange(index, 'variableMap', $event)}}})]:_vm._e(),(field.type === 'postItem')?[_c('v-text-field',{attrs:{"label":"URL","hint":"La solicitud POST será realizada por el cliente (navegador)","prepend-icon":"attach_file","value":field.options && field.options.url},on:{"input":function($event){return _vm.handleOptionChange(index, 'url', $event)}}})]:_vm._e(),(field.type === 'inlineBlock')?[_c('v-text-field',{attrs:{"label":"Ícono (contraido)","prepend-icon":_vm.normalizedIcon(
                          (field.options && field.options.icon) ||
                            'expand_more'
                        ),"placeholder":"expand_more","value":field.options.iconClosed,"clearable":""},on:{"input":function($event){return _vm.handleOptionChange(index, 'iconClosed', $event)}}}),_c('v-text-field',{attrs:{"label":"Ícono (expandido)","prepend-icon":_vm.normalizedIcon(
                          (field.options && field.options.icon) ||
                            'expand_less'
                        ),"placeholder":"expand_less","value":field.options.iconOpen,"clearable":""},on:{"input":function($event){return _vm.handleOptionChange(index, 'iconOpen', $event)}}}),_c('v-text-field',{attrs:{"label":"Tooltip (contraido)","prepend-icon":"text_format","clearable":"","placeholder":"Expandir","value":field.options.tooltipClosed},on:{"input":function($event){return _vm.handleOptionChange(index, 'tooltipClosed', $event)}}}),_c('v-text-field',{attrs:{"label":"Tooltip (expandido)","prepend-icon":"text_format","clearable":"","placeholder":"Contraer","value":field.options.tooltipOpen},on:{"input":function($event){return _vm.handleOptionChange(index, 'tooltipOpen', $event)}}}),_c('v-checkbox',{attrs:{"input-value":field.options.defaultOpen,"label":"Abierto por defecto"},on:{"change":function($event){return _vm.handleOptionChange(index, 'defaultOpen', $event)}}}),_c('component-select',{attrs:{"name":"blockId","schema":{
                        label: 'ID de bloque',
                        fieldOptions: {
                          componentTypeName: 'blocks'
                        }
                      },"environmentVariables":_vm.environmentVariables,"value":field.options.blockId},on:{"input":function($event){return _vm.handleOptionChange(index, 'blockId', $event)}}})]:_vm._e(),(field.type === 'modal')?[_c('v-select',{attrs:{"label":"Tipo de componente","prepend-icon":"extension","items":[
                        { value: 'block', text: 'Bloque de contenido' },
                        { value: 'form', text: 'Formulario' }
                      ],"clearable":"","value":field.options.componentType},on:{"input":function($event){return _vm.handleOptionChange(index, 'componentType', $event)}}}),(field.options.componentType)?_c('component-select',{attrs:{"name":"componentId","schema":{
                        label: 'Componente',
                        fieldOptions: {
                          componentTypeName: field.options.componentType + 's'
                        }
                      },"environmentVariables":_vm.environmentVariables,"value":field.options.componentId},on:{"input":function($event){return _vm.handleOptionChange(index, 'componentId', $event)}}}):_vm._e()]:_vm._e()],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.iconSheetOpen[index] = !_vm.iconSheetOpen[index]}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("check")]),_vm._v(" Aceptar ")],1)],1)],1):_vm._e()],1)],1):_c('span',[_vm._v("N/A")])],1),_c('td',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeField(index)}}},[_c('v-icon',[_vm._v("delete")])],1)],1)])}),0)],1),_c('v-row',{staticClass:"mx-0"},[_c('v-col',{staticClass:"pa-2"},[_c('v-btn',{attrs:{"block":"","dark":"","color":"#59a4f4"},on:{"click":_vm.addField}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("add")]),_vm._v(" Añadir Columna ")],1)],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.$apollo.loading,"width":"40px","block":"","dark":"","color":"#59a4f4"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" more_vert ")])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.addAllFields}},[_c('v-list-item-title',[_vm._v("Añadir todos los campos de la colección")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.removeAllFields}},[_c('v-list-item-title',[_vm._v("Limpiar todos campos")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }