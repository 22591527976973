

















import { Vue, Component } from 'vue-property-decorator'
import CodelessLoading from '@/components/CodelessLoading.vue'

@Component({
  components: {
    CodelessLoading
  }
})
export default class VueBlockEditorLoading extends Vue {}
