
















import { Component, Vue, Prop } from 'vue-property-decorator'
import { FileManagerFile, FormField } from '@/models'
import DownloadModal from './DownloadModal.vue'

interface FileMultipleFieldOptions {
  fileType: string
  thumbnail: boolean
}
@Component({
  components: {
    DownloadModal
  }
})
export default class FileMultipleFieldView extends Vue {
  @Prop({ type: Array, default: () => [] }) value!: FileManagerFile[] | []
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField

  downloadModalOpen = false

  openModal() {
    // if (this.value) return
    this.downloadModalOpen = true
  }

  /** Additional field options */
  get fieldOptions(): FileMultipleFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }
}
