import { Environment } from '@/models'
import { RootState } from '@/store/index'
import { Module } from 'vuex'
import getClient from '@/plugins/vue-apollo/client'
import gql from 'graphql-tag'

export interface EnvironmentState {
  environment: Environment | null
}

const defaults = {
  environment: null
}

/**
 * Environment module - state of the global environment.
 * This module is used to store the environment data.
 */
const EnvironmentModule: Module<EnvironmentState, RootState> = {
  namespaced: true,
  state: {
    ...defaults
  },
  mutations: {
    setEnvironment(state, environment: Environment) {
      state.environment = environment
    }
  },
  actions: {
    async fetchEnvironment({ commit }, url: string) {
      const client = getClient()
      const { data } = await client.query({
        query: gql`
          query getEnvironment($url: String) {
            environment(url: $url) {
              _id
              name
              homeRedirect
              allowSignUp
              logo {
                url
              }
              loginLogo {
                url
              }
              authBackgroundImage {
                url
              }
              icon {
                url
              }
              fontName
              accentColor
              allowDarkMode
              sideBarColor
              sideBarTextColor
              useCustomCss
              customCss
              backgroundColor
              timezone
              analyticsId
              enableTellus
              tellusChannels
              autoEnableNotifications
              notificationPrompt
              redirect
              redirectUrl
              maintenanceMode
              enableGlobalFooter
              globalFooter
            }
          }
        `,
        variables: {
          url
        }
      })
      // Cache response
      window.localStorage.setItem(
        'cachedEnvironment',
        JSON.stringify(data.environment)
      )
      commit('setEnvironment', data.environment)
    },
    initializeEnvironment(
      { commit, dispatch, state },
      url = window.environmentUrl || window.location.hostname
    ) {
      if (state.environment) return
      const cachedEnvironment = window.localStorage.getItem('cachedEnvironment')
      if (cachedEnvironment) {
        commit('setEnvironment', JSON.parse(cachedEnvironment))
        setTimeout(
          () =>
            dispatch('fetchEnvironment', url).catch((e) =>
              console.error(`Error refreshing environment cache: `, e)
            ),
          1000
        )
      } else {
        return dispatch('fetchEnvironment', url).catch((e) =>
          console.error(`Error refreshing environment cache: `, e)
        )
      }
    }
  }
}

export default EnvironmentModule
