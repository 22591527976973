






















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormField } from '@/models'
import Loading from '@/components/Loading.vue'
import 'codemirror/mode/xml/xml'
import 'codemirror/mode/handlebars/handlebars'
import 'codemirror/mode/css/css'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/mode/htmlmixed/htmlmixed'
import 'codemirror/mode/htmlembedded/htmlembedded'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'

// @ts-ignore
@Component({
  // @ts-ignore
  components: {
    CodeMirror: () => ({
      component: import('vue-codemirror').then((c) => c.codemirror),
      loading: Loading
    }),
    MonacoDialog: () => import('./MonacoDialog.vue')
  }
})
export default class CodeField extends Vue {
  /** Current Value */
  @Prop({ type: String, default: '' }) value!: string
  /** Validation Errors */
  @Prop({ type: Array, default: () => [] }) errorMessages!: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  /** Environment Variables */
  @Prop({ type: Object, default: () => ({}) }) environmentVariables!: Record<
    string,
    any
  >

  monacoDialogOpen = false

  /** Validation Rules */
  get validationRules() {
    const rules = []
    // Required validation
    if (!this.schema.optional) {
      rules.push((v?: string) => {
        console.log('rules',v)
        return !!v || 'Campo requerido.'
      })
    }
    return rules
  }

  /** Additional field options */
  get fieldOptions(): any {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  get currentValue() {
    return JSON.stringify(this.value) === 'null' ? '' : JSON.stringify(this.value)
  }

  set currentValue(newValue: string) {
  }
}
