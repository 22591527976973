import { ComponentType, Form } from '@/models'
import asyncImport from '@/utils/client/asyncImport'

export const forms = {
  name: 'form',
  namespace: 'forms',
  title: 'Formularios',
  titleSingle: 'Formulario',
  description: 'Procesa información',
  icon: 'keyboard',
  color: 'purple',
  overview:
    'Los formularios son usados para ingresar información a las colecciones. Con CODELESS puedes generar automáticamente formularios para cada una de las colecciones que tengas en tu proyecto.',

  queryName: 'forms',
  queryFields: `{
    _id
    name
    title
    type
    collectionId
  }`,
  searchable: true,

  transformResult: (form) => ({
    ...form,
    name: form.name,
    title: form.title,
    subtitle:
      {
        create: 'Crear registro',
        update: 'Actualizar registro existente',
        upsert: 'Crear o actualizar registro',
        void: 'Nulo ((ejecuta acciones sin almacenar datos)'
      }[form.type!] + ` en ${form.collectionId}`,
    href: `forms/${form._id}`
  }),

  viewSkeleton:
    'heading, table-cell, text, table-cell, text, table-cell, actions',

  create: () => asyncImport(import('./Create.vue')),
  view: () => asyncImport(import('./View.vue')),
  edit: () => asyncImport(import('./Edit.vue'))
} as ComponentType<Form>
