












































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { ComponentType, FormField, PaginatedQueryResult } from '@/models'
import * as ComponentTypes from '@/components/componentTypes'

interface ComponentSelectFieldOptions {
  multi?: boolean
  componentTypeName: string
  noEdit?: boolean
  noCreate?: boolean
  filterByKey?: string
  filterByValue?: string | string[]
  excludeByValue: string | string[]
  readonly?: boolean
  showChip?: boolean
  hideIcon?: boolean
  itemValue?: string
  disabledItems?: string[]
  enabledItems?: string[]
}

@Component({
  components: {
    ComponentEditorDialog: () =>
      import('@/components/ComponentEditorDialog.vue').then((c) => c.default),
    ComponentCreateDialog: () =>
      import('@/components/ComponentCreateDialog.vue').then((c) => c.default)
  },
  apollo: {
    result: {
      query() {
        const { queryName, queryFields } = this
          .componentType as ComponentType<any>
        return gql`query paginated_result ($environmentId : ID) {
          result: ${queryName} (environmentId: $environmentId) {
            items ${queryFields}
          }
        }`
      },
      fetchPolicy: 'network-only',
      variables() {
        return {
          environmentId: this.environmentVariables.environmentId
        }
      },
      skip() {
        return !this.componentType
      }
    }
  }
})
export default class ComponentSelectField extends Vue {
  /** Current Value */
  @Prop({ type: [String, Array], default: () => '' }) value!: string[] | string
  /** Validation Errors */
  @Prop() errorMessages!: string
  /** Field Name */
  @Prop({ type: String, required: true }) name!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  /** Environment Variables */
  @Prop({ type: Object, required: true }) environmentVariables!: Record<
    string,
    any
  >
  /** Outlined field */

  searchQuery = ''
  result: PaginatedQueryResult<any> | null = null

  createModalOpen = false
  componentEditorOpen = false
  componentEditorId = ''

  get itemValue() {
    return this.fieldOptions.itemValue || '_id'
  }

  get componentTypeName() {
    return this.fieldOptions.componentTypeName || ''
  }

  /** Component type */
  get componentType(): ComponentType<any> | null {
    if (!this.componentTypeName) return null
    return (ComponentTypes as Record<string, ComponentType<any>>)[
      this.componentTypeName
    ]
  }

  get items() {
    let arr = (this.result && this.result.items) || []
    if (this.componentType && this.componentType.transformResult) {
      arr = arr.map(this.componentType.transformResult)
    }
    if (this.fieldOptions.filterByKey && this.fieldOptions.excludeByValue) {
      if (typeof this.fieldOptions.excludeByValue === 'string') {
        arr = arr.filter(
          (c) =>
            c[this.fieldOptions.filterByKey!] !==
            this.fieldOptions.excludeByValue
        )
      } else {
        arr = arr.filter((c) =>
          !this.fieldOptions.excludeByValue!.includes(
            c[this.fieldOptions.filterByKey!]
          )
        )
      }
    }
    if (this.fieldOptions.filterByKey && this.fieldOptions.filterByValue) {
      if (typeof this.fieldOptions.filterByValue === 'string') {
        arr = arr.filter(
          (c) =>
            c[this.fieldOptions.filterByKey!] ===
            this.fieldOptions.filterByValue
        )
      } else {
        arr = arr.filter((c) =>
          this.fieldOptions.filterByValue!.includes(
            c[this.fieldOptions.filterByKey!]
          )
        )
      }
    }
    if (this.fieldOptions.disabledItems) {
      arr = arr.map((i) => ({
        ...i,
        disabled: this.fieldOptions.disabledItems!.indexOf(i._id) >= 0
      }))
    } else if (this.fieldOptions.enabledItems) {
      arr = arr.map((i) => ({
        ...i,
        disabled: this.fieldOptions.enabledItems!.indexOf(i._id) < 0
      }))
    }
    if (this.componentType?.create && !this.fieldOptions.noCreate) {
      arr.unshift({
        [this.itemValue]: '__create',
        name: `Crear ${this.componentType.titleSingle}`,
        icon: 'add'
      })
    }
    return arr
  }

  /** Additional field options */
  get fieldOptions(): ComponentSelectFieldOptions {
    return this.schema.fieldOptions || this.schema.options || {}
  }

  openComponentEditor(itemId: string) {
    this.componentEditorId = ''
    this.$nextTick(() => {
      this.componentEditorId = itemId
      this.componentEditorOpen = true
    })
  }

  /** Remove a component */
  remove(itemId: string) {
    if (typeof this.value === 'string') {
      if (this.value === itemId) this.$emit('input', null)
      return
    } else {
      this.$emit(
        'input',
        this.value.filter((i) => i !== itemId)
      )
    }
  }

  get currentValue() {
    return this.fieldOptions.multi ? this.selectedValues : this.selectedValue
  }

  set currentValue(v) {
    if (this.fieldOptions.multi) {
      this.selectedValues = typeof v === 'string' ? [v] : v
    } else {
      this.selectedValue = typeof v === 'string' ? v : v[0]
    }
  }

  @Watch('value')
  handleItemSelect(value: string | string[]) {
    if (!value) {
      this.searchQuery = ''
      return
    }
    const val = typeof value === 'string' ? [value] : value
    if (value.includes('__create')) {
      this.$nextTick(() => this.remove('__create'))
      this.createModalOpen = true
    }
  }

  async handleCreate(item: any) {
    if (!item || !item._id) return
    if (this.fieldOptions.multi) {
      this.$emit('input', [...this.selectedValues, item[this.itemValue]])
    } else {
      this.$emit('input', item[this.itemValue])
    }
    await this.$apollo.queries.result.refetch()
    this.openComponentEditor(item._id)
  }

  clear() {
    if (this.fieldOptions.readonly) return
    this.$emit('input', null)
  }

  /** Current Value */
  get selectedValue() {
    if (!this.value) return ''
    return typeof this.value === 'string' ? this.value : this.value[0]
  }

  set selectedValue(value: string) {
    this.$emit('input', this.fieldOptions.multi ? value[0] : value)
  }

  /** Current Values (multi) */
  get selectedValues() {
    return typeof this.value === 'string' ? [this.value] : this.value
  }

  set selectedValues(value: string[]) {
    this.$emit('input', value)
  }
}
