import { ComponentType, Validation } from '@/models'
import asyncImport from '@/utils/client/asyncImport'

export const validations = {
  name: 'validation',
  namespace: 'validations',
  title: 'Validaciones',
  titleSingle: 'Validación',
  icon: 'spellcheck',
  color: 'blue-grey',
  overview:
    'Las validaciones son comprobaciones que se pueden realizar dentro de campos determinados de una colección o incluso sobre archivos. Con CODELESS puedes crear tus propias validaciones y luego ejecutarlos dentro de tu proyecto.',

  queryName: 'validations',
  queryFields: `{
    _id
    name
    validationTypeId
  }`,
  searchable: true,
  hidden: true,

  transformResult: (validation) => ({
    ...validation,
    name: validation.name,
    title: validation.name,
    subtitle: validation.validationTypeId,
    href: `validations/${validation._id}`
  }),

  list: () => asyncImport(import('./List.vue')),
  create: () => asyncImport(import('./Create.vue')),
  edit: () => asyncImport(import('./Edit.vue'))
} as ComponentType<Validation>
