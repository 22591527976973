

import { Vue, Component, Prop } from 'vue-property-decorator'
import gql from 'graphql-tag'
import _debounce from 'lodash/debounce'

@Component({
  apollo: {
    $subscribe: {
      collectionDataChanged: {
        query: gql`
          subscription collectionDataChanged(
            $environmentId: ID
            $collectionId: ID
          ) {
            collectionDataChanged(
              environmentId: $environmentId
              collectionId: $collectionId
            )
          }
        `,
        variables() {
          return {
            environmentId:
              this.environmentId || this.collectionId.split('_')[0],
            collectionId: this.collectionId
          }
        },
        result({ data }: any) {
          this.emitUpdate()
        }
      }
    }
  }
})
export default class CollectionWatcher extends Vue {
  @Prop({ type: String }) environmentId?: string
  @Prop({ type: String, required: true }) collectionId!: string

  render() {
    return null
  }

  mounted() {
    // this.emitUpdate = _debounce(() => this.$emit('update'), 500)
  }

  emitUpdate() {
    this.$emit('update')
  }
}
