import Vue from 'vue'
import getEnv from './plugins/getEnv'
if (getEnv('VUE_APP_ENV') === 'beta') {
  Vue.config.devtools = true
}

import vuetify from './plugins/vuetify'

import App from './App.vue'
import router from './router'

import './styles/index.styl'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'katex/dist/katex.min.css'

import store from './store'
import './plugins/routeAuth'
import './plugins/closeDialogs'
import './plugins/vue-shepherd'
import './plugins/vuelayers'
import './plugins/vue-input-facade'
import apolloProvider from './plugins/vue-apollo/provider'

Vue.config.productionTip = false
declare global {
  interface Window {
    mobileApp?: boolean
    environmentUrl?: string
    intro?: any
    codelessDesktop?: any
  }
}

new Vue({
  router,
  store,
  apolloProvider,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
