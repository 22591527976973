











































import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormField } from '@/models'

interface TimeFieldOptions {}

@Component({})
export default class TimeField extends Vue {
  /** Current Value */
  @Prop({ type: String }) value!: string
  /** Validation Errors */
  @Prop() errorMessages!: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean

  open = false

  /** Validation Rules */
  get validationRules() {
    const rules = []
    // Required validation
    if (!this.schema.optional) {
      rules.push((v?: number | null) => !!v || 'Campo Requerido.')
    }
    return rules
  }

  get displayValue() {
    return this.value ? this.value : '--:--'
  }

  /** Additional field options */
  get fieldOptions(): TimeFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  /** Input event handler */
  handleInput(value: string) {
    this.$emit('input', value)
    this.open = false
  }
}
