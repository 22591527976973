import { ComponentType, ApiEndpoint } from '@/models'
import EditorLoading from './FullViewEdit/Loading.vue'
import ComponentError from '@/components/ComponentError.vue'

export const apiEndpoints = {
  name: 'apiEndpoint',
  namespace: 'api',
  title: 'API',
  titleSingle: 'API Endpoint',
  description: 'Endpoint de una API REST',
  icon: 'account_tree',
  overview: '',
  queryName: 'apiEndpoints',
  queryFields: `{
    _id
    name
    path
    method
  }`,
  color: 'brown',
  searchable: true,
  hidden: true,

  transformResult: (apiEndpoint) => ({
    ...apiEndpoint,
    name: apiEndpoint.name
  }),
  
  fullViewEdit: () => ({
    component: import('./FullViewEdit/FullViewEdit.vue').then(e => e.default),
    loading: EditorLoading,
    error: ComponentError
  } as unknown as Vue.AsyncComponent),
} as ComponentType<ApiEndpoint>
