import { ComponentType, Chart } from '@/models'
import asyncImport from '@/utils/client/asyncImport'

export const charts = {
  name: 'chart',
  namespace: 'charts',
  title: 'Gráficos',
  titleSingle: 'Gráfico',
  description: 'Visualiza métricas',
  icon: 'poll',
  color: 'indigo',
  overview:
    'Los gráficos son usados para mostrar información de una forma gráfica, visualmente amigables y fáciles de entender. Con CODELESS puedes crear tus propios gráficos a partir de los datos que almacenes en tus colecciones.',

  queryName: 'charts',
  queryFields: `{
    _id
    name
    title
    chartTypeId
  }`,
  searchable: true,

  transformResult: (chart) => ({
    ...chart,
    href: `charts/${chart._id}`
  }),

  create: () => asyncImport(import('./Create.vue')),
  view: () => asyncImport(import('./View.vue')),
  edit: () => asyncImport(import('./Edit.vue')),
  list: () => asyncImport(import('./List.vue'))
} as ComponentType<Chart>
