



























































import { Component, Vue, Prop } from 'vue-property-decorator'
import { FormField } from '@/models'

interface StringFieldOptions {
  icon?: string
  validatePattern?: string
  validateCaseInsensitive?: boolean
  validateCustomError?: string
  min?: number
  max?: number
  placeholder?: string
}

@Component({})
export default class StringField extends Vue {
  /** Current Value */
  @Prop({ type: String, default: '' }) value!: string
  /** Validation Errors */
  @Prop() errorMessages!: string | string[]
  /** Field Name */
  @Prop({ type: String, required: true }) name!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean

  /** Validation Rules */
  get validationRules() {
    const rules = []
    // Required validation
    if (!this.schema.optional) {
      rules.push((v?: string) => !!v || 'Campo requerido.')
    }
    // Custom validation pattern
    if (this.fieldOptions.validatePattern) {
      rules.push(
        (v?: string) =>
          !v ||
          !!v.match(
            new RegExp(
              this.fieldOptions.validatePattern!,
              this.fieldOptions.validateCaseInsensitive ? 'i' : ''
            )
          ) ||
          this.fieldOptions.validateCustomError ||
          'Formato inválido'
      )
    }
    // Length validations
    if (this.fieldOptions.min) {
      rules.push(
        (v?: string) =>
          !v || v.length >= this.fieldOptions.min! || 'Demasiado corto'
      )
    }
    if (this.fieldOptions.max) {
      rules.push(
        (v?: string) =>
          !v || v.length <= this.fieldOptions.max! || 'Demasiado largo'
      )
    }
    return rules
  }

  /** Additional field options */
  get fieldOptions(): StringFieldOptions {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  get currentValue() {
    return this.value
  }

  set currentValue(value: string) {
    this.$emit('input', value)
  }

  clearValue() {
    this.$emit('input', null)
  }
}
