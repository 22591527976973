export interface Experiment {
  id: string
  name: string
  description: string
}

export const availableExperiments: Experiment[] = [
  {
    id: 'vue-properties-window',
    name: 'Editor de propiedades (Vue)',
    description:
      'Muestra las propiedades del elemento seleccionado en el editor de plantillas'
  }
]
