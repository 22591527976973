var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"pr-5 styled-toolbar",attrs:{"app":"","dense":"","absolute":"","dark":_vm.$route.meta.darkNavbar,"height":"50","elevation":"0"}},[(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-app-bar-nav-icon',{attrs:{"elevation":"0"},on:{"click":_vm.setDrawer}}):_vm._e(),(_vm.user)?_c('v-menu',{attrs:{"transition":"slide-y-transition","nudge-width":100},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","disabled":_vm.desktopApp}},on),[_c('h4',[_vm._v(" "+_vm._s(_vm.currentView && _vm.currentView.label)+" ")]),_c('v-icon',{attrs:{"dark":""}},[_vm._v("arrow_drop_down")])],1)]}}],null,false,3693181937)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.$router.push('/')}}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("home")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Inicio")]),_c('v-list-item-subtitle',[_vm._v("Página Principal")])],1)],1),_c('v-divider',{staticStyle:{"margin":"0.5em 0"}}),_c('v-subheader',[_vm._v("RECIENTE")]),_vm._l((_vm.$store.state.app.recents),function(env){return _c('v-list-item',{key:env._id,on:{"click":function($event){return _vm.changeEnvironment(env._id)}}},[_c('v-list-item-avatar',[(env.loginLogo && env.loginLogo.url)?_c('img',{staticStyle:{"height":"auto","border-radius":"0"},attrs:{"src":env.loginLogo.url}}):(env.logo && env.logo.url)?_c('img',{staticStyle:{"height":"auto","border-radius":"0"},attrs:{"src":env.logo.url}}):_c('v-icon',[_vm._v("apps")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(env.name)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(env._id)}})],1)],1)})],2)],1):_vm._e(),_c('v-spacer'),(_vm.experimentsAvailable)?[_c('v-btn',{attrs:{"icon":"","color":_vm.$store.getters['experiments/hasActiveExperiments']
          ? 'warning'
          : undefined},on:{"click":function($event){_vm.experimentsDialog = true}}},[_c('v-icon',[_vm._v("science")])],1)]:_vm._e(),(_vm.currentEnvironment)?[_c('v-btn',{attrs:{"icon":"","href":_vm.environmentLink,"target":"_blank"}},[_c('v-icon',[_vm._v("open_in_new")])],1)]:_vm._e(),_c('v-btn',{attrs:{"text":"","href":"https://academy.simplex.biz/wiki/Codeless","target":"_blank"}},[_vm._v(" Docs ")]),(_vm.user)?[_c('v-menu',{attrs:{"transition":"slide-y-transition","nudge-width":100},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-avatar',{attrs:{"color":"grey lighten-4","size":32}},[_c('img',{attrs:{"src":_vm.userAvatar,"alt":"Avatar"}})])],1)]}}],null,false,4092039376)},[_c('v-list',[_c('v-list-item',{attrs:{"to":"/","disabled":_vm.desktopApp}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(
                _vm.user && ((_vm.user.profile && _vm.user.profile.name) || _vm.user.email)
              )}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm.userRoleDisplay)}})],1),_c('v-list-item-avatar',[_c('img',{attrs:{"src":_vm.userAvatar,"alt":"Avatar"}})])],1),_c('v-divider',{staticStyle:{"margin":"0.5em 0"}}),_c('v-list-item',{on:{"click":_vm.toggleDarkMode}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Modo oscuro")])],1),_c('v-list-item-avatar',[(_vm.$store.state.app.darkMode)?_c('v-icon',{attrs:{"color":"primary","size":"48"}},[_vm._v("toggle_on")]):_c('v-icon',{attrs:{"size":"48"}},[_vm._v("toggle_off")])],1),_c('v-list-item-avatar',[_c('v-icon',[_vm._v("brightness_medium")])],1)],1),(!_vm.desktopApp)?_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Cerrar Sesión")])],1),_c('v-list-item-avatar',[_c('v-icon',[_vm._v("exit_to_app")])],1)],1):_vm._e()],1)],1)]:[_c('v-btn',{attrs:{"outlined":"","to":"/login"}},[_vm._v("Iniciar Sesión "),_c('v-icon',[_vm._v("person")])],1)],_c('ExperimentsDialog',{model:{value:(_vm.experimentsDialog),callback:function ($$v) {_vm.experimentsDialog=$$v},expression:"experimentsDialog"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }